import React, { useState, useEffect } from "react";

const Chat = () => {
  // State to keep track of the current input/message
  const [currentMessage, setCurrentMessage] = useState("");
  // const server = 'http://localhost:5001';
  const server = "https://frozen-springs-61240.herokuapp.com";

  // State to keep all chat messages. Each message is an object with text and an author property.
  const [messages, setMessages] = useState([
    {
      text: "Hi, I'm Theresa. Before we begin, I should inform you that while I am designed to help you with your mental health, you should not consider me a replacement for a real therapist.", author: "ai"
    },
    {
      text: "That said, How can I help you?", author: "ai"
    },
  ]);

  // Function to simulate receiving a message from "another user"
  const getReply = async () => {
    // You could replace this with an actual API call for AI-based responses.
    //create new array for messages where it's a series of arrays with the first field being the author and the second field being the message

    // let messageArray = [];
    // for (let i = 0; i < messages.length; i++) {
    //   messageArray.push([messages[i].author, messages[i].text]);
    // }
    const response = await fetch(`${server}/therapise`, {
      method: "POST",
      mode: 'cors',
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({ message: currentMessage, history: messages.slice(2) }),
    })
      .then((res) => res.json())

    console.log(response);
    console.log(JSON.stringify(response));
    return response.message.response;
  };

  // Function to handle when a user sends a message
  const handleSendMessage = async () => {
    if (!currentMessage.trim()) return;

    const newMessage = { text: currentMessage, author: "human" };
    setMessages([...messages, newMessage]);

    // Clear the input field after a message is sent
    setCurrentMessage("");

    // Simulate getting a reply
    // In a real-world scenario, this could be an API request to get an AI response
    const reply = await getReply();
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: reply, author: "ai" },
    ]);
  };

  // Scroll to bottom whenever the list of messages changes
  useEffect(() => {
    const chatContainer = document.querySelector(".chat-container");
    chatContainer.scrollTop = chatContainer.scrollHeight;
  }, [messages]);

  return (
    <div className="flex flex-col h-full bg-gray-200">
      {/* Chat container */}
      <div className="overflow-auto chat-container flex-1 p-4" style={{ maxHeight: '70vh' }}>
        {/* Messages */}
        {messages.map((message, index) => (
          <div key={index} className={`flex ${message.author === "ai" ? "justify-start" : "justify-end"} mb-4`}>
            <div
              className={`rounded-lg px-4 py-2 ${
                message.author === "ai"
                  ? "bg-blue-600 text-white"
                  : "bg-gray-300 text-gray-700"
              }`}
            >
              {message.text}
            </div>
          </div>
        ))}
      </div>

      {/* Message input */}
      <div className="border-t-2 border-gray-300 p-4">
        <div className="flex">
          <input
            type="text"
            className="flex-1 px-4 py-2 rounded-full border-2 border-gray-300"
            placeholder="Type your message..."
            value={currentMessage}
            onChange={(e) => setCurrentMessage(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
          />
          <button
            className="bg-blue-600 text-white rounded-full ml-4 px-6 py-2"
            onClick={handleSendMessage}
          >
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

export default Chat;
