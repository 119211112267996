// src/pages/HomePage.js
import React from 'react';
import Chat from '../components/Chat';

const HomePage = () => {
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl text-center my-4">Work on yourself</h1>
      <div className="flex justify-center items-center">
        <div className="w-full lg:w-1/2 bg-white p-4 rounded shadow">
          {/* Here we're using the Chat component */}
          <Chat />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
