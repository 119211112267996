import logo from './logo.svg';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import './App.css';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { useRef } from 'react';




function App() {
  const tawkMessengerRef = useRef();

  const onBeforeLoad = () => {
    try{
      tawkMessengerRef.current.minimize();
    } catch (error) {
    }
  }
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
        </Routes>
      </Router>
      <TawkMessengerReact 
          propertyId="6535e735f2439e1631e73384"
          widgetId="1hdd8uaej"
          onBeforeLoad={onBeforeLoad}
          ref={tawkMessengerRef}      />
    </div>
  );
}

export default App;
